<template>
  <div class="application">
    <!-- 主体 -->
    <div v-loading="loading" class="content">
      <div class="left">
        <div
          v-for="(item, i) in typeList"
          :key="i"
          :class="{ active: i === activeColor }"
          class="type"
          @click="clickHandle(item.id, item.typeName, i)"
        >
          <svg-icon
            :icon-class="[
              i === activeColor ? item.typeIcon + '-on' : item.typeIcon,
            ]"
          />
          <span>{{ item.typeName }}</span>
        </div>
        <div class="install-bg">
          <div class="install" @click="clickHandle('', installName)" />
        </div>
      </div>
      <div class="right">
        <div class="header">
          <div class="title">{{ title }}</div>
          <div>
            <!-- <a-input ref="search" v-model="search" placeholder="搜索">
                            </a-input> -->
            <el-input
              v-model="search"
              class="search"
              placeholder="搜索"
              prefix-icon="Search"
              clearable
              @input="searchHandle(search)"
            />
          </div>
        </div>
        <div v-if="title === '已安装'" class="flex-style">
          <div v-for="(item, i) in appList" :key="i">
            <div
              v-if="item.defaults === 0 && item.useful === 1"
              class="application"
              @contextmenu.prevent="rightClick(item, $event)"
              @dblclick="dbClick(item)"
            >
              <div class="img-box">
                <img :src="item.icon">
              </div>
              <div class="name">
                {{ item.appName }}
              </div>
            </div>
            <div
              v-if="item.defaults === 1 && item.useful === 1"
              class="application"
              @dblclick="dbClick(item)"
            >
              <div class="img-box">
                <img :src="item.icon">
              </div>
              <div class="name">
                {{ item.appName }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex-style">
          <div v-for="(item, i) in appList" :key="i">
            <div
              v-if="item.defaults === 0 && item.useful === 1"
              class="application"
              @contextmenu.prevent="rightClick(item, $event)"
            >
              <div class="img-box">
                <img :src="item.icon">
              </div>
              <div class="name">
                {{ item.appName }}
              </div>
            </div>
            <div
              v-if="item.defaults === 1 && item.useful === 1"
              class="application"
            >
              <div class="img-box">
                <img :src="item.icon">
              </div>
              <div class="name">
                {{ item.appName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="menuVisible" id="contextmenu" class="appMenu">
      <div v-if="title === '已安装'">
        <div class="contextmenu-item" @click="uninstall(CurrentRow)">
          <svg-icon icon-class="uninstall" />
          卸载
        </div>
      </div>
      <div v-else>
        <div
          v-if="CurrentRow.usePerson !== 1"
          class="contextmenu-item"
          @click="install(CurrentRow)"
        >
          <svg-icon icon-class="start" />
          安装
        </div>
        <div
          v-if="CurrentRow.usePerson === 1"
          class="contextmenu-item"
          @click="uninstall(CurrentRow)"
        >
          <svg-icon icon-class="uninstall" />
          卸载
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryTypeList,
  queryAppByType,
  addPersonApp,
  uninstallApp,
  installApp
} from '@/api/application'
import { ElMessage } from 'element-plus'
// import { Search } from '@element-plus/icons-vue'
export default {
  data() {
    return {
      menuVisible: false,
      // 应用分类
      activeColor: 0,
      search: '',
      tId: '',
      loading: true,
      installName: '已安装',
      title: '全部应用',
      appList: [],
      typeList: [],
      CurrentRow: '',
      timer: null
    }
  },
  computed: {
    appsList() {
      var appsList = []
      appsList = this.$store.state.core.applications
      console.log(appsList)
      // this.clickHandle()
      return appsList
    }
  },
  mounted() {
    this.getTypedata()
    // this.getAppData()
  },
  methods: {
    // 安装
    install(row) {
      const data = {
        appId: row.id,
        link: true,
        orders: 0,
        x: 600,
        y: 400
      }
      addPersonApp(data).then((response) => {
        if (response.meta.status === 201) {
          ElMessage.success('安装成功')
          this.$store.commit('core/APP_INIT')
          this.getAppData()
        }
      })
    },
    // 卸载
    uninstall(row) {
      uninstallApp(row.id).then((response) => {
        if (response.meta.status === 204) {
          ElMessage.success('卸载成功')
          this.$store.dispatch('core/close', row.id)
          this.$store.commit('core/APP_INIT')
          if (this.title === '已安装') {
            // this.appList = this.appsList
            this.getInstallData()
          } else {
            this.getAppData()
          }
        }
      })
    },
    // 双击打开已安装
    dbClick(row) {
      this.$store.dispatch('core/openApp', row.id)
    },
    // 右键
    rightClick(row, event) {
      this.testModeCode = row.testModeCode
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault() // 关闭浏览器右键默认事件
      this.CurrentRow = row
      var menu = document.querySelector('.appMenu')
      this.styleMenu(menu, event)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      window.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu(menu, event) {
      var y = event.clientY
      var x = event.clientX
      menu.style.left = x + 'px'
      window.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    getTypedata() {
      queryTypeList().then((response) => {
        this.typeList = response.data
        this.typeList.unshift({
          typeName: '全部应用',
          typeIcon: 'all',
          id: ''
        })
        this.getAppData()
      })
    },
    getInstallData() {
      this.loading = true
      installApp(this.search).then((response) => {
        console.log(JSON.stringify(response))
        this.appList = response.data
        this.loading = false
      })
    },
    getAppData() {
      this.loading = true
      const arr = []
      queryAppByType(this.tId, this.search).then((response) => {
        this.appList = response.data.content
        this.loading = false
      })
    },
    clickHandle(id, name, i) {
      this.tId = id
      this.activeColor = i
      this.title = name
      if (name === '已安装') {
        // this.appList = this.appsList
        this.getInstallData()
        return
      }
      this.$nextTick(() => {
        this.getAppData()
      })
    },
    // 模糊查询
    searchHandle() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (this.title !== '已安装') {
          this.getAppData()
        } else {
          this.getInstallData()
        }
      }, 500)
    }
  }
}
</script>

<style scoped lang="less">
.application {
  height: 100%;
  .appMenu {
    position: fixed;
    background-color: #fff;
    width: 160px;
    padding: 0 10px;
    height: 31.5px;
    font-size: 12px;
    color: #313a46;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e8eef3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 10000;
    .svg-icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 11px;
    }
  }
  .context-menu-section {
    z-index: 100000;
  }
  .content {
    height: 100%;
    display: flex;
    font-size: 12px;
    .left {
      min-width: 219px;
      height: 100%;
      overflow-y: scroll;
      box-sizing: border-box;
      font-weight: 500px;
      font-size: 14px;
      padding:20px 20px 65px 0;
      margin-left: 20px;
      border-right: 1px solid #d8d8d8;

      .install-bg {
        width: 190px;
        height: 60px;
        background: #ffffff;
        position: absolute;
        bottom: 0;
        left: 12px;
        .install {
          // // margin-left: 5px;
          position: absolute;
          bottom: 15px;
          left: 1px;
          background: url("../assets/icon/install.png") center no-repeat;
          width: 63px;
          height: 63px;
          // height: 30%;
        }
        .install:hover {
          background: url("../assets/icon/install-on.png") center no-repeat;
        }
      }
      .svg-icon {
        width: 21px;
        height: 21px;
        vertical-align: middle;
      }
      .type {
        cursor: pointer;
        width: 179px;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        padding-left: 13px;
        padding-bottom: 20px;
      }
      span {
        padding-left: 7px;
        vertical-align: middle;
      }
      .active {
        color: #2385ff;
        background: #f2f9ff;
        border-radius: 2px;
      }
    }
    .right {
      padding: 20px;
      width: 100%;
      color: #202020;
      font-size: 18px;
      overflow-y: scroll;
      .header {
        min-width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #d8d8d8;
        ::v-deep .el-icon{
          color: #dcdfe6;
          font-size: 16px;
        }
        ::v-deep .el-input {
          --el-input-focus-border: #dcdfe6;
        }
        .ant-input {
          border-radius: 17px;
          // width: 400px;
        }
        ::v-deep .el-input__inner {
          border-radius: 2px;
          height: 30px;
          line-height: 30px;
        }
      }
      .flex-style {
        display: flex;
        flex-wrap: wrap;
        min-width: 300px;
      }
      .application {
        text-align: center;
        width: 110px;
        .img-box {
          img {
            width: 60px;
            height: 60px;
            // border-radius: 20px;
            // box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
          }
        }
        .name {
          width: 100%;
          padding-top: 10px;
          font-size: 12px;
          margin-bottom: 30px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
